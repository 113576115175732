import { useCallback, useMemo, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useQuery, useQueryClient } from "react-query";
import { useAxios } from "../../hooks";
import { useMutation } from "react-query";
import ComfirmDialog from "../common/ConfirmDialog";
import { getDateTime } from "../../utilities/getDateTime";

export default function RequestCompany({
  handleOnSelecting,
  startDate,
  endDate,
}) {
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ company_id: "", action: "" });

  const handleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const queryKey = `company-list_request_page=${page}_rowsPerPage=${pageSize}_startDate=${
    startDate
  }_endDate=${endDate}`;

  const { data: res, isLoading } = useQuery(
    queryKey,
    () => {
      return api.get(
        `/api/company/i/list/REQUESTED/p?page=${page}&rowsPerPage=${pageSize}${
          startDate ? `&startDate=${startDate}` : ""
        }${endDate ? `&endDate=${endDate}` : ""}`
      );
    },
    {
      keepPreviousData: true,
    }
  );

  const { mutate } = useMutation(
    () => {
      return api.put(
        `/api/company/i/status/${data.company_id}?status=${data.action}`
      );
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", minWidth: 90, flex: 0.5 },
      { field: "name", headerName: "Company Name", minWidth: 100, flex: 1 },
      { field: "phone_number", headerName: "Phone", minWidth: 100, flex: 1 },
      { field: "address", headerName: "Address", minWidth: 100, flex: 1 },
      {
        field: "email",
        headerName: "Email",
        width: 150,
        minWidth: 100,
        flex: 1,
      },
      { field: "credit", headerName: "Credit", minWidth: 100, flex: 1 },
      {
        field: "reserved_credit",
        headerName: "Reserve",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 100,
        flex: 1,
        valueFormatter: (params) => getDateTime(params.value),
      },
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Button
            variant="outlined"
            color="success"
            size="small"
            onClick={() => {
              setData({
                company_id: params.id,
                action: "ACTIVE",
              });
              handleOpen();
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Activate</Typography>
          </Button>
        ),
      },
    ],
    [setData, handleOpen]
  );

  return (
    <>
      <DataGrid
        columns={columns}
        rowCount={res?.data?.total ?? 0}
        rows={res?.data?.rows ?? []}
        loading={isLoading}
        pagination
        paginationMode="server"
        checkboxSelection
        onSelectionModelChange={handleOnSelecting}
        page={page}
        pageSize={pageSize}
        onPageChange={(v) => setPage(v)}
        onPageSizeChange={(v) => setPageSize(v)}
      />
      <ComfirmDialog
        open={open}
        handleClose={handleOpen}
        handleClickOpen={handleOpen}
        handleOnConfirm={mutate}
      />
    </>
  );
}
