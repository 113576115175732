import React, { useCallback, useMemo, useState } from "react";
import { useAxios } from "../../hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ComfirmDialog from "../common/ConfirmDialog";
import { useParams } from "react-router-dom";
import { getDateTime } from "../../utilities/getDateTime";

const CompanyBrandNameList = ({ handleOnSelecting }) => {
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({
    matchCompanyBrandNameId: "",
    brandNameId: "",
    companyId: "",
  });

  const handleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const companyId = parseInt(id, 10);

  const { data: res, isLoading } = useQuery(
    `brand_name_list_active_${companyId}`,
    () => {
      return api.get(`/api/brandname/i/match-company-brandname/${id}`);
    }
  );

  const { mutate } = useMutation(
    () => {
      const res = api.delete(
        `/api/brandname/i/match-company-brandname/${selectedData.matchCompanyBrandNameId}/${selectedData.brandNameId}/${selectedData.companyId}`
      );
      return res;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`company_brandname_lists`);
        queryClient.invalidateQueries(`brand_name_list_active_${companyId}`);
      },
    }
  );

  const columns = useMemo(
    () => [
      {
        field: "match_company_brandname_id",
        headerName: "ID",
        minWidth: 90,
        flex: 0.5,
      },
      {
        field: "sender_id",
        headerName: "Sender Name",
        minWidth: 100,
        flex: 1,
        valueGetter: (params) => params.row.sender_id,
      },
      {
        field: "application_status",
        headerName: "Status",
        minWidth: 100,
        flex: 1,
        valueGetter: (params) => params.row.application_status,
      },
      {
        field: "updated_at",
        headerName: "Updated At",
        minWidth: 100,
        flex: 1,
        valueFormatter: (params) => getDateTime(params.value),
      },
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={() => {
              setSelectedData({
                matchCompanyBrandNameId: params.row.match_company_brandname_id,
                brandNameId: params.row.brandname_id,
                companyId: params.row.company_id,
              });
              handleOpen();
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Remove</Typography>
          </Button>
        ),
      },
    ],
    [handleOpen]
  );
  return (
    <>
      <DataGrid
        columns={columns}
        rows={res?.data ?? []}
        getRowId={(row) => row.match_company_brandname_id}
        loading={isLoading}
      />
      <ComfirmDialog
        open={open}
        handleClose={handleOpen}
        handleClickOpen={handleOpen}
        handleOnConfirm={() => {
          mutate();
        }}
      />
    </>
  );
};

export default CompanyBrandNameList;
