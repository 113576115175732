import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {DialogContent,  useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useAxios } from "../../hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getDateTime } from "../../utilities/getDateTime";
import { useState } from "react";
import { useParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddBrandNamesDialog({ open, handleClose }) {
  const isSmallToMediumScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );
  const { id } = useParams();

  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [selectedBrandName, setSelectedBrandName] = useState({
    brandname_id: null,
    company_id: null,
    created_by: null,
  });
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

  const { data: userData } = useQuery(
    `current_user`,
    () => api.get(`/api/user/me`),
    {
      onSuccess: (data) => {
        setCurrentUserId(data?.data?.id);
      },
    }
  );

  const { data: res, isLoading } = useQuery(`company_brandname_lists`, () => {
    return api.get(`/api/brandname/i/brandname/${companyId}`);
  });

  const companyId = parseInt(id, 10);
  const { mutate } = useMutation(
    () => {
      return api.post(`/api/brandname/i/match-company-brandname`, {
        brandname_id: selectedBrandName.brandname_id,
        company_id: companyId,
        created_by: currentUserId,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`company_brandname_lists`);
        queryClient.invalidateQueries(`brand_name_list_active_${companyId}`);
        setSelectedBrandName({
          brandname_id: null,
          company_id: null,
          created_by: null,
        });
        setIsAddButtonDisabled(true);
      },
      onError: (error) => {
        console.error("Error adding brand name:", error);
      },
    }
  );

  const handleSelectionModelChange = (selectionModel) => {
    if (selectionModel.length === 1) {
      const selectedRowData = res?.data.find(
        (row) => row.id === selectionModel[0]
      );
      if (selectedRowData) {
        setSelectedBrandName({
          brandname_id: selectedRowData.id,
          company_id: companyId,
          created_by: currentUserId,
        });
      }
      setIsAddButtonDisabled(false);
    } else {
      setSelectedBrandName({
        brandname_id: null,
        company_id: null,
        created_by: null,
      });
      setIsAddButtonDisabled(true);
    }
  };

  const handleAddClick = () => {
    if (selectedBrandName.brandname_id && selectedBrandName.company_id) {
      mutate();
      handleClose();
    } else {
      console.error("Brand name or company ID is missing");
    }
  };

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", minWidth: 90, flex: 0.5 },
      {
        field: "company_id",
        headerName: "Company ID",
        minWidth: 100,
        flex: 1,
        valueGetter: (params) => params.row.company_id,
      },
      {
        field: "sender_id",
        headerName: "Sender Name",
        minWidth: 100,
        flex: 1,
        valueGetter: (params) => params.row.sender_id,
      },
      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 100,
        flex: 1,
        valueFormatter: (params) => getDateTime(params.value),
      },
    ],
    []
  );

  return (
    <Dialog
      open={open}
      fullScreen={isSmallToMediumScreen}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: { height: { md: 400 }, minWidth: { lg: 600, md: 600 } },
      }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Brandnames
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={handleAddClick}
            disabled={isAddButtonDisabled}
          >
            Add
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <DataGrid
          columns={columns}
          rowCount={res?.data?.total ?? 0}
          rows={res?.data ?? []}
          loading={isLoading}
          pagination
          paginationMode="server"
          checkboxSelection
          onSelectionModelChange={handleSelectionModelChange}
          page={page}
          pageSize={pageSize}
          onPageChange={(v) => setPage(v)}
          onPageSizeChange={(v) => setPageSize(v)}
        />
      </DialogContent>
    </Dialog>
  );
}
