import React, { useMemo, useState } from "react";
import { useAxios } from "../../hooks";
import { useQuery } from "react-query";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import NoRowOverlay from "../common/NoRowOverlay";
import { getDateTime } from "../../utilities/getDateTime";

const SuccessOrder = ({ handleOnSelecting, startDate, endDate }) => {
  const api = useAxios({ autoSnackbar: true });
  const apiRef = useGridApiRef();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const queryKey = `order-list_success_page=${page}_rowsPerPage=${pageSize}_startDate=${startDate}_endDate=${endDate}`;

  const { data: res, isLoading } = useQuery(
    queryKey,
    () => {
      return api.get(
        `/api/order/i/SUCCESS/p?page=${page}&rowsPerPage=${pageSize}${
          startDate ? `&startDate=${startDate}` : ""
        }${endDate ? `&endDate=${endDate}` : ""}`
      );
    },
    {
      keepPreviousData: true,
    }
  );

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", minWidth: 90, flex: 0.5 },
      {
        field: "order_number",
        headerName: "Order Number",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "company_name",
        headerName: "Company Name",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "package_name",
        headerName: "Package Name",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "package_credit",
        headerName: "Package Credit",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "package_price",
        headerName: "Package Price",
        minWidth: 100,
        flex: 1,
      },
      { field: "package_tax", headerName: "Tax", minWidth: 100, flex: 1 },
      {
        field: "updated_at",
        headerName: "Updated At",
        minWidth: 100,
        flex: 1,
        valueFormatter: (params) => getDateTime(params.value),
      },
      {
        field: "note",
        headerName: "Note",
        minWidth: 200,
        flex: 1,
        hide: true,
      },
    ],
    []
  );

  return (
    <>
      <DataGrid
        apiRef={apiRef}
        columns={columns}
        rowCount={res?.data?.total ?? 0}
        rows={res?.data?.rows ?? []}
        loading={isLoading}
        pagination
        paginationMode="server"
        checkboxSelection
        onSelectionModelChange={handleOnSelecting}
        page={page}
        pageSize={pageSize}
        onPageChange={(v) => setPage(v)}
        onPageSizeChange={(v) => setPageSize(v)}
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: NoRowOverlay,
        }}
        componentsProps={{
          toolbar: {
            csvOptions: { allColumns: true },
          },
        }}
      />
    </>
  );
};

export default SuccessOrder;
