import { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Tab, Tabs } from "@mui/material";
import TabPanel from "../../components/common/TabPanel";
import {
  RequestCompany,
  ActiveCompany,
  InactiveCompany,
} from "../../components/company";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../hooks";
import { useSetRecoilState } from "recoil";
import { withAlert } from "../../recoil/snackbar";
import { useMutation } from "react-query";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const CompanyList = () => {
  const navigate = useNavigate();
  const api = useAxios({ autoSnackbar: true });
  const openAlert = useSetRecoilState(withAlert);

  const [tab, setTab] = useState(0);
  const [selection, setSelection] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  const { mutate: downloadReport, isLoading: isDownloading } = useMutation(
    () => {
      return api.post(
        `/api/company/download`,
        { company_ids: selection },
        { responseType: "blob" }
      );
    },
    {
      onSuccess: (response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], { type: "application/zip" })
        );

        const a = document.createElement("a");
        a.href = url;
        a.download = `company_reports.zip`;
        a.click();

        URL.revokeObjectURL(url);

        openAlert({
          status: 200,
          detail: "Report downloaded successfully.",
        });
      },
      onError: (error) => {
        openAlert({
          status: error.response?.status || 500,
          detail: "Failed to download the report.",
        });
        console.error("Download error:", error);
      },
    }
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          height: "90%",
          width: "100%",
          px: { xs: "1rem", lg: "2rem" },
          mt: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <Tabs
            value={tab}
            onChange={handleChange}
            variant="scrollable"
            allowScrollButtonsMobile
            sx={{
              minHeight: "24px",
              "& .MuiTab-root": { minHeight: "24px", padding: "4px" },
              pt: 2,
            }}
          >
            <Tab label="Request" />
            <Tab label="Active" />
            <Tab label="Inactive" />
          </Tabs>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{
                gap: 2,
              }}
            >
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <Box>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    display: selection.length >= 1 ? "block" : "none",
                  }}
                  onClick={() => downloadReport()}
                  disabled={isDownloading}
                >
                  {selection.length <= 1 ? "Download " : "Download As List"}
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    display: selection.length === 1 ? "block" : "none",
                  }}
                  onClick={() => navigate("details/" + selection[0])}
                >
                  Details
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <TabPanel value={tab} index={0}>
          <RequestCompany
            handleOnSelecting={setSelection}
            startDate={startDate ? dayjs(startDate).toISOString() : null}
            endDate={endDate ? dayjs(endDate).toISOString() : null}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ActiveCompany
            handleOnSelecting={setSelection}
            startDate={startDate ? dayjs(startDate).toISOString() : null}
            endDate={endDate ? dayjs(endDate).toISOString() : null}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <InactiveCompany
            handleOnSelecting={setSelection}
            startDate={startDate ? dayjs(startDate).toISOString() : null}
            endDate={endDate ? dayjs(endDate).toISOString() : null}
          />
        </TabPanel>
      </Box>
    </LocalizationProvider>
  );
};

export default CompanyList;
