import { useState } from "react";
import { Box, Tab, Tabs, TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import TabPanel from "../../components/common/TabPanel";
import {
  PendingOrder,
  SuccessOrder,
  FailedOrder,
} from "../../components/order";

const OrderList = () => {
  const [tab, setTab] = useState(0);
  const [selection, setSelection] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          height: "90%",
          width: "100%",
          mt: 3,
          px: { xs: "1rem", lg: "2rem" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Tabs
            value={tab}
            onChange={handleChange}
            variant="scrollable"
            allowScrollButtonsMobile
            sx={{
              minHeight: "24px",
              "& .MuiTab-root": { minHeight: "24px", padding: "4px" },
              pt: 2,
            }}
          >
            <Tab label="Pending" />
            <Tab label="Success" />
            <Tab label="Failed" />
          </Tabs>

          <Box display="flex" gap={2} alignItems="center">
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </Box>
        </Box>

        <TabPanel value={tab} index={0}>
          <PendingOrder
            handleOnSelecting={setSelection}
            startDate={startDate ? dayjs(startDate).toISOString() : null}
            endDate={endDate ? dayjs(endDate).toISOString() : null}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <SuccessOrder
            handleOnSelecting={setSelection}
            startDate={startDate ? dayjs(startDate).toISOString() : null}
            endDate={endDate ? dayjs(endDate).toISOString() : null}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <FailedOrder
            handleOnSelecting={setSelection}
            startDate={startDate ? dayjs(startDate).toISOString() : null}
            endDate={endDate ? dayjs(endDate).toISOString() : null}
          />
        </TabPanel>
      </Box>
    </LocalizationProvider>
  );
};

export default OrderList;
